import React from 'react'
import { Container } from './styles'
import Internado from './Internado'
import Maternidade from './Maternidade'
import CondicoesMedicas from './CondicoesMedicas'
import Evacuacao from './Evacuacao'

function Content() {
  return (
    <Container>
      <h1>TCTP - Tabela de Terminologia Atual dos Procedimentos</h1>
      <Internado />
      <Maternidade />
      <CondicoesMedicas />
      <Evacuacao />
    </Container>
  )
}

export default Content
