import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Banner from '../components/Banner'
import Content from '../sections/Caracteristicas/Content'

const IndexPage = () => (
  <Layout>
    <SEO title="Características do Plano e Benefícios | Master International" />
    <Banner
      pageTitle="Características"
      heading="TABELA DE BENEFÍCIOS"
      height={250}
      filename="caracteristicas-banner"
    />
    <Content />
  </Layout>
)

export default IndexPage
