import styled, { css } from 'styled-components'
import { Table, TableHeader, TableRow } from '../../../components/Table'

const m512 = css`
  @media (max-width: 512px) {
    div:first-child,
    div:last-child {
      width: 100%;
      justify-content: space-between;
    }
  }
`

export const Container = styled.section`
  width: 100%;
  max-width: 1045px;
  margin: auto;

  h1 {
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
    font-weight: normal;
    color: #ae9962;
    margin-top: 96px;
    margin-bottom: 56px;
    text-align: center;
  }

  small {
    float: right;
    margin-top: 15px;
  }

  @media (max-width: 512px) {
    small {
      text-align: center;
    }
  }
`

export const CTable = styled(Table)``

export const CTableHeader = styled(TableHeader)`
  &:first-child {
    background: #aaaaaa;
  }

  div:last-child {
    width: 45.516% !important;
    justify-content: space-between;
  }

  @media (max-width: 512px) {
    h3 {
      font-size: 12px;
      text-align: center;
    }
  }
`

export const CTableRow = styled(TableRow)`
  div:last-child {
    width: 44.2%;
    padding-right: 52px;

    &.col-2 {
      width: 48%;

      > p:first-child {
        width: 51%;
        text-align: center;
      }
    }
  }

  ${m512}
`

export const MTableHeader = styled(CTableHeader)`
  div:last-child {
    justify-content: center;
    text-align: center;
    width: 34.5% !important;
  }
`

export const MTableRow = styled(CTableRow)`
  div:first-child {
    width: 56.771%;
  }

  div:last-child {
    width: 33%;
    justify-content: center;
    text-align: center;
  }

  ${m512}
`
