import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, CTable, MTableHeader, MTableRow } from '../styles'

function Evacuacao() {
  const { markdownRemark } = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { path: { eq: "evacuacao" } }) {
        frontmatter {
          beneficios {
            id
            descricao
            autorizacao
          }
        }
      }
    }
  `)

  return (
    <Container>
      <CTable>
        <MTableHeader>
          <div>
            <h3>DESCRIÇÃO DE BENEFÍCIOS E LIMITAÇÕES DE EVACUAÇÃO</h3>
          </div>
          <div>
            <h3>AUTORIZAÇÃO PRÉVIA DA USA MEDICAL SERVICE É NECESSÁRIA</h3>
          </div>
        </MTableHeader>
        {markdownRemark.frontmatter.beneficios.map(b => (
          <MTableRow key={b.id}>
            <div>
              <p dangerouslySetInnerHTML={{ __html: b.descricao }} />
            </div>
            <div>
              <p dangerouslySetInnerHTML={{ __html: b.autorizacao }} />
            </div>
          </MTableRow>
        ))}
      </CTable>
    </Container>
  )
}

export default Evacuacao
