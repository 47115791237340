import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, CTable, CTableHeader, CTableRow } from '../styles'

function Internado() {
  const { markdownRemark } = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { path: { eq: "internado" } }) {
        frontmatter {
          beneficios {
            id
            descricao
            prestador
            escolha
            col
          }
        }
      }
    }
  `)

  return (
    <Container>
      <CTable>
        <CTableHeader>
          <div>
            <h3>DESCRIÇÃO DE BENEFÍCIOS E LIMITAÇÕES DE PACIENTE INTERNADO</h3>
          </div>
          <div>
            <h3>PRESTADOR BUPA</h3>
            <h3>LIVRE ESCOLHA</h3>
          </div>
        </CTableHeader>
        {markdownRemark.frontmatter.beneficios.map(b => (
          <CTableRow key={b.id}>
            <div>
              <p dangerouslySetInnerHTML={{ __html: b.descricao }} />
            </div>
            <div className={b.col ? b.col : undefined}>
              <p>{b.prestador}</p>
              <p>{b.escolha}</p>
            </div>
          </CTableRow>
        ))}
      </CTable>
    </Container>
  )
}

export default Internado
